import { hostname } from "./api";
import * as fetchUtils from "ra-core/lib/dataProvider/fetch";
import omitBy from 'lodash.omitby';
import { stringify } from 'query-string';
import { useMemo } from "react";
import { validateSupplier } from "../utils/utils";

const fetchJson = token => (url, options = {}) => {
  options.user = {
    authenticated: true,
    token: 'Bearer ' + token,
  };
  return fetchUtils.fetchJson(url, options);
};

const countDiff = (o1, o2) =>
  omitBy(o1, (v, k) => o2[k] === v);

const enumTypes = {
  process: ["tier"],
  views: ["product"]
};

const composeFilter = (paramsFilter, resource) => {
  if (!paramsFilter || Object.keys(paramsFilter).length === 0) {
    return null;
  }

  const flatFilter = fetchUtils.flattenObject(paramsFilter);
  const filters = [];

  Object.keys(flatFilter).forEach((key) => {
    const splitKey = key.split('||');
    let field = splitKey[0];
    let ops = splitKey[1];

    if (field.startsWith('_') && field.includes('.')) {
      field = field.split(/\.(.+)/)[1];
    }

    let filterStr;
    if (!ops) {
      if (Array.isArray(flatFilter[key])) {
        filterStr = `${field}||$in||${flatFilter[key].join(',')}`;
      } else if (enumTypes[resource] && enumTypes[resource].includes(key)) {
        filterStr = `${field}||$eq||${flatFilter[key]}`;
      }
      else if (
        typeof flatFilter[key] === 'boolean' ||
        typeof flatFilter[key] === 'number' ||
        (
          typeof flatFilter[key] === 'string' &&
          flatFilter[key].match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/)
        )
      ) {
        filterStr = `${field}||$eq||${flatFilter[key]}`;
      } else {
        // Para búsquedas de texto, no incluimos los % ya que el backend los maneja
        filterStr = `${field}||$like||${flatFilter[key]}`;
      }
    } else {
      // Si el operador viene especificado, lo usamos tal cual
      filterStr = `${field}||${ops}||${flatFilter[key]}`;
    }

    filters.push(filterStr);
  });

  return filters;
};

const composeQueryParams = (queryParams = {}) => {
  const params = { ...queryParams };
  if (params.filter && Array.isArray(params.filter) && params.filter.length > 0) {
    // Convertir el array de filtros en un objeto con índices
    const filterObj = params.filter.reduce((acc, filter, index) => {
      acc[`filter[${index}]`] = filter;
      return acc;
    }, {});
    delete params.filter;
    Object.assign(params, filterObj);
  } else {
    delete params.filter;
  }
  return stringify(fetchUtils.flattenObject(params), { skipNull: true });
};


const joinResources = {
  'process': 'preProcesses'
};

// Mapeo de recursos para casos especiales donde el nombre del recurso es diferente en la API
const resourceMapping = {
  'ecom-reference-article': 'ecommerceReference',
  'supplier': 'supplier'
};

/**
 * Array con los 'resource' que requieren endpoint con /:accountId,
 * sin tocar la lógica especial de 'articles' ya existente en getList.
 */
const resourcesWithAccountId = [
  'materialProcess',
  'materialCertificate',
  'supplier',
  'supplierCertificate',
  'retailPackaging',
  'articlesHazardou',
  'articleHazardous',
  'articleMaterial',
  'articleSupplier',
  'articlePackaging',
  'articleProcess',
  'articlesummary',
  'ecommerceReference',
  'ecom-reference-article',
  'accountAgecSummary',
  'projects',
  'library',
  'library/adminLibrary',
  'accountFeatures',
  'material',
  'views'
];

/**
 * Este provider mantiene la lógica previa y solo se añaden los ajustes
 * estrictamente necesarios para los nuevos endpoints con /:accountId.
 */
const crudProvider = (apiUrl, httpClient = fetchUtils.fetchJson, tokenOps) => ({

  getList: (resource, params) => {

    console.log(resource)

    // Usar el nombre mapeado del recurso si existe
    const apiResource = resourceMapping[resource] || resource;

    // Special case for library resource - use adminLibrary endpoint for GET requests
    const effectiveResource = apiResource === 'library' ? 'library/adminLibrary' : apiResource;

    // Construimos el query string base
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      limit: perPage,
      page: page,
      sort: [`${field},${order}`],
    };

    // Agregamos joins si existen para el recurso
    if (effectiveResource !== 'process' && Object.keys(joinResources).includes(effectiveResource)) {
      query.join = joinResources[effectiveResource];
    }

    // Agregamos filtros si existen
    const { filter } = params;
    if (filter) {
      query.filter = composeFilter(filter, effectiveResource);
    }

    // Lista de recursos que devuelven arrays directamente
    const arrayResources = ['rawMaterialCategory'];

    // Caso especial para transportOrigin que tiene una estructura diferente
    if (effectiveResource === 'transportOrigin') {
      // Usamos processOrigin/transport como ruta de API pero mantenemos transportOrigin como nombre de recurso
      const apiResource = 'processOrigin/transport';
      const queryString = composeQueryParams(query);
      const url = queryString ? `${apiUrl}/${apiResource}?${queryString}` : `${apiUrl}/${apiResource}`;

      console.log('URL for transportOrigin:', url);

      return httpClient(url).then(({ json }) => {
        console.log('Response from transportOrigin:', json);
        
        if (!json || !json.data) {
          console.error('No data received from transportOrigin endpoint');
          return {
            data: [],
            total: 0,
          };
        }
        
        // Asegurarse de que los datos tengan la estructura correcta para React-Admin
        return {
          data: json.data,
          total: json.count || json.total || (json.data ? json.data.length : 0),
        };
      }).catch(error => {
        console.error('Error fetching transportOrigin data:', error);
        return {
          data: [],
          total: 0,
        };
      });
    }

    // Caso especial para transport que debe usar process/transport como endpoint
    if (effectiveResource === 'transport') {
      // Usamos process/transport como ruta de API pero mantenemos transport como nombre de recurso
      const apiResource = 'process/transport';
      const queryString = composeQueryParams(query);
      const url = queryString ? `${apiUrl}/${apiResource}?${queryString}` : `${apiUrl}/${apiResource}`;

      console.log('URL for transport:', url);

      return httpClient(url).then(({ json }) => {
        console.log('Response from transport:', json);
        
        if (!json || !json.data) {
          console.error('No data received from transport endpoint');
          return {
            data: [],
            total: 0,
          };
        }
        
        // Asegurarse de que los datos tengan la estructura correcta para React-Admin
        return {
          data: json.data,
          total: json.count || json.total || (json.data ? json.data.length : 0),
        };
      }).catch(error => {
        console.error('Error fetching transport data:', error);
        return {
          data: [],
          total: 0,
        };
      });
    }

    // Para adminViews, mantenemos el formato original con accountId como query param
    if (effectiveResource === 'views/adminViews') {
      const accountId = tokenOps?.account?.data?.accountId;
      if (!accountId) {
        console.warn('[DEBUG] getList -> No accountId found for adminViews');
        return Promise.reject('No accountId found');
      }

      const queryString = composeQueryParams({
        ...query,
        accountId,
      });

      const url = `${apiUrl}/${effectiveResource}?${queryString}`;

      return httpClient(url).then(({ json }) => {
        if (!json.data) {
          return {
            data: [],
            total: 0,
          };
        }
        return {
          data: json.data,
          total: json.total || json.data.length,
        };
      });
    }

    // Manejo especial para el recurso users
    if (apiResource === 'users') {
      const accountId = tokenOps?.account?.data?.accountId;
      if (!accountId) {
        return Promise.reject('No accountId found');
      }

      const queryString = composeQueryParams(query);
      const urlWithAccount = queryString
        ? `${apiUrl}/${apiResource}/fromAccount/${accountId}?${queryString}`
        : `${apiUrl}/${apiResource}/fromAccount/${accountId}`;

      return httpClient(urlWithAccount).then(({ json }) => {
        // Si la respuesta es un array directo
        if (Array.isArray(json)) {
          return {
            data: json,
            total: json.length,
          };
        }
        
        if (!json.data) {
          return {
            data: [],
            total: 0,
          };
        }
        return {
          data: json.data,
          total: json.total || json.data.length,
        };
      }).catch(error => {
        console.error('Error fetching users data:', error);
        return {
          data: [],
          total: 0,
        };
      });
    }

    // Para recursos que necesitan accountId en la URL
    if (resourcesWithAccountId.includes(effectiveResource) || effectiveResource === 'articles' || effectiveResource === 'ecommerceReference') {
      const accountId = tokenOps?.account?.data?.accountId;
      if (!accountId) {
        console.warn(`[DEBUG] getList -> Resource: ${effectiveResource} -> No accountId found; using fallback URL`);
      } else {
        const queryString = composeQueryParams(query);
        const urlWithAccount = queryString
          ? `${apiUrl}/${effectiveResource}/${accountId}?${queryString}`
          : `${apiUrl}/${effectiveResource}/${accountId}`;

        return httpClient(urlWithAccount).then(({ json }) => {
          // Si la respuesta es un array directo
          if (Array.isArray(json)) {
            return {
              data: json,
              total: json.length,
            };
          }

          if (!json.data) {
            return {
              data: [],
              total: 0,
            };
          }
          return {
            data: json.data,
            total: json.total || json.data.length,
          };
        });
      }
    }

    // URL fallback para recursos que no necesitan accountId
    const queryString = composeQueryParams(query);
    const url = queryString ? `${apiUrl}/${effectiveResource}?${queryString}` : `${apiUrl}/${effectiveResource}`;

    return httpClient(url).then(({ json }) => {
      // Si es un recurso que devuelve array directo o la respuesta es un array
      if (arrayResources.includes(effectiveResource) || Array.isArray(json)) {
        return {
          data: Array.isArray(json) ? json : [],
          total: Array.isArray(json) ? json.length : 0,
        };
      }

      if (!json.data) {
        return {
          data: [],
          total: 0,
        };
      }
      return {
        data: json.data,
        total: json.total || json.data.length,
      };
    });
  },

  getOne: (resource, params) => {

    // Usar el nombre mapeado del recurso si existe
    const apiResource = resourceMapping[resource] || resource;

    // Special case for library resource - use adminLibrary endpoint for GET requests
    const effectiveResource = apiResource === 'library' ? 'library/adminLibrary' : apiResource;

    const query = {};
    if (effectiveResource !== 'process' && Object.keys(joinResources).includes(effectiveResource)) {
      query.join = joinResources[effectiveResource];
    }

    // Lista de recursos que solo necesitan accountId en getOne
    const resourcesWithOnlyAccountId = ['accountFeatures', 'articleHazardous', 'articlePackaging'];

    // Caso especial para transportOrigin
    if (effectiveResource === 'transportOrigin') {
      // Usamos processOrigin/transport como ruta de API pero mantenemos transportOrigin como nombre de recurso
      const apiResource = 'processOrigin/transport';
      const queryString = composeQueryParams(query);
      const url = queryString
        ? `${apiUrl}/${apiResource}/${params.id}?${queryString}`
        : `${apiUrl}/${apiResource}/${params.id}`;

      console.log('URL for transportOrigin getOne:', url);

      return httpClient(url).then(({ json }) => {
        console.log('Response from transportOrigin getOne:', json);
        
        // Si la respuesta es un objeto directo o está dentro de data
        return {
          data: json?.data || json
        };
      }).catch(error => {
        console.error('Error fetching transportOrigin item:', error);
        if (error.status === 404) {
          return { data: null };
        }
        throw error;
      });
    }

    // Caso especial para transport
    if (effectiveResource === 'transport') {
      // Usamos process/transport como ruta de API pero mantenemos transport como nombre de recurso
      const apiResource = 'process/transport';
      const queryString = composeQueryParams(query);
      const url = queryString
        ? `${apiUrl}/${apiResource}/${params.id}?${queryString}`
        : `${apiUrl}/${apiResource}/${params.id}`;

      console.log('URL for transport getOne:', url);

      return httpClient(url).then(({ json }) => {
        console.log('Response from transport getOne:', json);
        
        // Si la respuesta es un objeto directo o está dentro de data
        return {
          data: json?.data || json
        };
      }).catch(error => {
        console.error('Error fetching transport item:', error);
        if (error.status === 404) {
          return { data: null };
        }
        throw error;
      });
    }

    // Manejo especial para el recurso users
    if (apiResource === 'users') {
      const accountId = tokenOps?.account?.data?.accountId;
      if (!accountId) {
        return Promise.reject('No accountId found');
      }

      const queryString = composeQueryParams(query);
      const urlWithAccount = queryString
        ? `${apiUrl}/${apiResource}/${params.id}?${queryString}`
        : `${apiUrl}/${apiResource}/${params.id}`;

      return httpClient(urlWithAccount).then(({ json }) => {
        // Si la respuesta es un array directamente (no dentro de data)
        if (Array.isArray(json)) {
          const item = json[0];
          if (item) {
            return { data: item };
          }
          return { data: null };
        }
        
        // Si la respuesta es un array dentro de data
        if (Array.isArray(json?.data)) {
          const item = json.data[0];
          if (item) {
            return { data: item };
          }
          return { data: null };
        }
        
        // Si la respuesta ya tiene la estructura correcta
        if (json?.id || (json?.data && json.data?.id)) {
          return {
            data: json?.data || json
          };
        }

        return { data: null };
      }).catch(error => {
        if (error.status === 404) {
          return { data: null };
        }
        throw error;
      });
    }

    // Si el recurso está en la lista de /:accountId, agregamos esa parte.
    if (resourcesWithAccountId.includes(effectiveResource) || effectiveResource === 'articles' || effectiveResource === 'ecommerceReference') {
      const accountId = tokenOps?.account?.data?.accountId;
      if (!accountId) {
        console.warn(`[DEBUG] getOne -> Resource: ${effectiveResource} -> No accountId found; using fallback URL`);
      } else {
        let urlWithAccount;

        // Para recursos que solo necesitan accountId
        if (resourcesWithOnlyAccountId.includes(effectiveResource)) {
          const queryString = composeQueryParams(query);
          urlWithAccount = queryString
            ? `${apiUrl}/${effectiveResource}/${accountId}?${queryString}`
            : `${apiUrl}/${effectiveResource}/${accountId}`;
        }
        // Para el recurso views
        else if (effectiveResource === 'views') {
          const queryString = composeQueryParams(query);
          urlWithAccount = queryString
            ? `${apiUrl}/${effectiveResource}/${accountId}/${params.id}?${queryString}`
            : `${apiUrl}/${effectiveResource}/${accountId}/${params.id}`;
        }
        // Para el resto de recursos (incluyendo ecommerceReference)
        else {
          const queryString = composeQueryParams(query);
          urlWithAccount = queryString
            ? `${apiUrl}/${effectiveResource}/${accountId}/${params.id}?${queryString}`
            : `${apiUrl}/${effectiveResource}/${accountId}/${params.id}`;
        }


        return httpClient(urlWithAccount).then(({ json }) => {
          // Si la respuesta es un array directamente (no dentro de data)
          if (Array.isArray(json)) {
            const item = json[0];
            if (item) {
              return { data: item };
            }
            return { data: null };
          }

          // Si la respuesta es un array dentro de data
          if (Array.isArray(json?.data)) {
            const item = json.data[0];
            if (item) {
              return { data: item };
            }
            return { data: null };
          }

          // Si la respuesta ya tiene la estructura correcta
          if (json?.id || (json?.data && json.data?.id)) {
            return {
              data: json?.data || json
            };
          }

          // Si llegamos aquí, significa que no pudimos encontrar el item
          console.warn(`[DEBUG] getOne - Could not find item with id ${params.id} in response:`, json);
          return { data: null };
        }).catch(error => {
          console.error('[DEBUG] getOne - Error Details:', {
            status: error.status,
            statusText: error.statusText,
            message: error.message,
            resource,
            id: params.id,
            url: urlWithAccount
          });

          if (error.status === 404) {
            console.warn(`[DEBUG] getOne - Resource not found: ${resource}, ID: ${params.id}`);
            return { data: null };
          }
          throw error;
        });
      }
    }

    // Fallback original
    const queryString = composeQueryParams(query);
    const fallbackUrl = queryString
      ? `${apiUrl}/${effectiveResource}/${params.id}?${queryString}`
      : `${apiUrl}/${effectiveResource}/${params.id}`;

    return httpClient(fallbackUrl).then(({ json }) => {
      // Si la respuesta es un array directamente (no dentro de data)
      if (Array.isArray(json)) {
        const item = json[0];
        if (item) {
          return { data: item };
        }
        return { data: null };
      }

      // Si la respuesta es un array dentro de data
      if (Array.isArray(json?.data)) {
        const item = json.data[0];
        if (item) {
          return { data: item };
        }
        return { data: null };
      }

      // Si la respuesta ya tiene la estructura correcta
      if (json?.id || (json?.data && json.data?.id)) {
        return {
          data: json?.data || json
        };
      }

      // Si llegamos aquí, significa que no pudimos encontrar el item
      console.warn(`[DEBUG] getOne - Could not find item with id ${params.id} in response:`, json);
      return { data: null };
    }).catch(error => {
      console.error('[DEBUG] getOne - Fallback Error Details:', {
        status: error.status,
        statusText: error.statusText,
        message: error.message,
        resource,
        id: params.id,
        url: fallbackUrl
      });

      if (error.status === 404) {
        console.warn(`[DEBUG] getOne - Fallback resource not found: ${resource}, ID: ${params.id}`);
        return { data: null };
      }
      throw error;
    });
  },

  getMany: (resource, params) => {
    const effectiveResource = resourceMapping[resource] || resource;
    
    // Caso especial para transport
    if (effectiveResource === 'transport') {
      // Usamos process/transport como ruta de API pero mantenemos transport como nombre de recurso
      const apiResource = 'process/transport';
      
      const queryParams = {
        filter: `id||$in||${params.ids.join(',')}`
      };
      
      const queryString = stringify(queryParams);
      const url = `${apiUrl}/${apiResource}?${queryString}`;
      
      console.log('URL for transport getMany:', url);
      
      return httpClient(url).then(({ json }) => {
        console.log('Response from transport getMany:', json);
        
        if (!json || !json.data) {
          console.error('No data received from transport endpoint');
          return { data: [] };
        }
        
        return { data: json.data };
      }).catch(error => {
        console.error('Error fetching transport items:', error);
        return { data: [] };
      });
    }

    // Special case for hazardousSubstances that returns direct array
    if (resource === 'hazardousSubstances') {
      const url = `${apiUrl}/${resource}`;

      return httpClient(url).then(({ json }) => {
        // Filter the array to only include requested IDs
        const dataArray = Array.isArray(json) ? json.filter(item => params.ids.includes(item.id)) : [];
        return { data: dataArray };
      });
    }

    // Fallback for other resources
    const filters = {};
    filters[`filter[0]`] = `id||$in||${params.ids.join(',')}`;

    const queryString = new URLSearchParams(filters).toString();
    const url = `${apiUrl}/${effectiveResource}?${queryString}`;

    return httpClient(url).then(({ json }) => ({
      data: Array.isArray(json.data) ? json.data : (validateSupplier(url) ? [json] : Array.isArray(json) ? json : [])
    }));
  },

  getManyReference: (resource, params) => {
    const { target, id, pagination, sort, filter } = params;
    const query = {
      filter: { ...filter, [target]: id },
      sort: { field: sort.field, order: sort.order },
      pagination: { page: pagination.page, perPage: pagination.perPage },
    };
    
    // Caso especial para referencias a transport desde transportOrigin
    if (resource === 'transport' && target === 'transportId') {
      // Usamos process/transport como ruta de API pero mantenemos transport como nombre de recurso
      const apiResource = 'process/transport';
      const { page, perPage } = query.pagination;
      const { field, order } = query.sort;
      
      const apiQuery = {
        limit: perPage,
        page: page,
        sort: [`${field},${order}`],
      };
      
      if (query.filter) {
        apiQuery.filter = composeFilter(query.filter, resource);
      }
      
      const queryString = composeQueryParams(apiQuery);
      const url = queryString ? `${apiUrl}/${apiResource}?${queryString}` : `${apiUrl}/${apiResource}`;
      
      console.log('URL for transport getManyReference:', url);
      
      return httpClient(url).then(({ json }) => {
        console.log('Response from transport getManyReference:', json);
        
        if (!json || !json.data) {
          console.error('No data received from transport endpoint');
          return {
            data: [],
            total: 0,
          };
        }
        
        // Procesamos la estructura específica donde transport es un objeto directo
        const data = json.data.map(item => {
          // Si el item tiene una propiedad transport que es un objeto, lo usamos directamente
          if (item.transport && typeof item.transport === 'object') {
            return {
              ...item.transport,
              id: item.transport.id
            };
          }
          return item;
        });
        
        return {
          data: data,
          total: json.count || json.total || (json.data ? json.data.length : 0),
        };
      }).catch(error => {
        console.error('Error fetching transport reference data:', error);
        return {
          data: [],
          total: 0,
        };
      });
    }
    
    return this.getList(resource, query);
  },

  update: (resource, params) => {
    const effectiveResource = resourceMapping[resource] || resource;
    
    // Caso especial para transportOrigin
    if (effectiveResource === 'transportOrigin') {
      // Usamos processOrigin/transport como ruta de API pero mantenemos transportOrigin como nombre de recurso
      const apiResource = 'processOrigin/transport';
      const url = `${apiUrl}/${apiResource}/${params.id}`;
      
      console.log('URL for transportOrigin update:', url);
      console.log('Data for transportOrigin update:', params.data);
      
      return httpClient(url, {
        method: 'PUT',
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        console.log('Response from transportOrigin update:', json);
        return {
          data: json?.data || json,
        };
      }).catch(error => {
        console.error('Error updating transportOrigin item:', error);
        throw error;
      });
    }
    
    // Caso especial para transport
    if (effectiveResource === 'transport') {
      // Usamos process/transport como ruta de API pero mantenemos transport como nombre de recurso
      const apiResource = 'process/transport';
      const url = `${apiUrl}/${apiResource}/${params.id}`;
      
      console.log('URL for transport update:', url);
      console.log('Data for transport update:', params.data);
      
      return httpClient(url, {
        method: 'PUT',
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        console.log('Response from transport update:', json);
        return {
          data: json?.data || json,
        };
      }).catch(error => {
        console.error('Error updating transport item:', error);
        throw error;
      });
    }
    
    // Resto del código original...
    const data = countDiff(params.data, params.previousData);
    const url = `${apiUrl}/${resource}/${params.id}`;

    return httpClient(url, {
      method: 'PATCH',
      body: JSON.stringify(data),
    }).then(({ json }) => ({ data: json }));
  },

  updateMany: (resource, params) => {

    return Promise.all(
      params.ids.map((id) => {
        const url = `${apiUrl}/${resource}/${id}`;
        return httpClient(url, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        });
      })
    ).then((responses) => ({
      data: responses.map(({ json }) => json),
    }));
  },

  create: (resource, params) => {
    const effectiveResource = resourceMapping[resource] || resource;

    // Caso especial para transportOrigin
    if (effectiveResource === 'transportOrigin') {
      // Usamos processOrigin/transport como ruta de API pero mantenemos transportOrigin como nombre de recurso
      const apiResource = 'processOrigin/transport';
      const url = `${apiUrl}/${apiResource}`;

      console.log('URL for transportOrigin create:', url);
      console.log('Data for transportOrigin create:', params.data);

      return httpClient(url, {
        method: 'POST',
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        console.log('Response from transportOrigin create:', json);
        return {
          data: json?.data || json,
        };
      }).catch(error => {
        console.error('Error creating transportOrigin item:', error);
        throw error;
      });
    }

    // Caso especial para transport
    if (effectiveResource === 'transport') {
      // Usamos process/transport como ruta de API pero mantenemos transport como nombre de recurso
      const apiResource = 'process/transport';
      const url = `${apiUrl}/${apiResource}`;

      console.log('URL for transport create:', url);
      console.log('Data for transport create:', params.data);

      return httpClient(url, {
        method: 'POST',
        body: JSON.stringify(params.data),
      }).then(({ json }) => {
        console.log('Response from transport create:', json);
        return {
          data: json?.data || json,
        };
      }).catch(error => {
        console.error('Error creating transport item:', error);
        throw error;
      });
    }

    // Resto del código original...
    const url = `${apiUrl}/${effectiveResource}`;

    return httpClient(url, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  delete: (resource, params) => {
    const effectiveResource = resourceMapping[resource] || resource;
    
    // Caso especial para transportOrigin
    if (effectiveResource === 'transportOrigin') {
      // Usamos processOrigin/transport como ruta de API pero mantenemos transportOrigin como nombre de recurso
      const apiResource = 'processOrigin/transport';
      const url = `${apiUrl}/${apiResource}/${params.id}`;
      
      console.log('URL for transportOrigin delete:', url);
      
      return httpClient(url, {
        method: 'DELETE',
      }).then(({ json }) => {
        console.log('Response from transportOrigin delete:', json);
        return { data: params.previousData };
      }).catch(error => {
        console.error('Error deleting transportOrigin item:', error);
        throw error;
      });
    }
    
    // Caso especial para transport
    if (effectiveResource === 'transport') {
      // Usamos process/transport como ruta de API pero mantenemos transport como nombre de recurso
      const apiResource = 'process/transport';
      const url = `${apiUrl}/${apiResource}/${params.id}`;
      
      console.log('URL for transport delete:', url);
      
      return httpClient(url, {
        method: 'DELETE',
      }).then(({ json }) => {
        console.log('Response from transport delete:', json);
        return { data: params.previousData };
      }).catch(error => {
        console.error('Error deleting transport item:', error);
        throw error;
      });
    }
    
    // Resto del código original...
    const url = `${apiUrl}/${resource}/${params.id}`;

    return httpClient(url, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: { ...json, id: params.id } }));
  },

  deleteMany: (resource, params) => {

    return Promise.all(
      params.ids.map((id) => {
        const url = `${apiUrl}/${resource}/${id}`;

        return httpClient(url, {
          method: 'DELETE',
        });
      })
    ).then((responses) => ({ data: responses.map(({ json }) => json) }));
  },
});

export const useDataProvider = (tokenOps) => {
  return useMemo(() => {
    return crudProvider(hostname, fetchJson(tokenOps.account?.accessToken), tokenOps);
  }, [tokenOps.account?.accessToken]);
};

export default useDataProvider;
