import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DateField,
  Resource,
  Show,
  ReferenceInput,
  SelectInput,
  Filter,
  ReferenceField,
  AutocompleteInput,
  Datagrid } from "react-admin";


const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <ReferenceInput label="Transport" source="transportId" reference="transport">
        <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Origin" source="originId" reference="origins" filterToQuery={searchText => ({ name: searchText })} >
        <AutocompleteInput optionText="name"  />
    </ReferenceInput>
    <ReferenceInput label="Destination" source="destinationId" reference="origins">
        <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const TransportOriginList = (props) => {
  console.log('TransportOriginList props:', props);
  return (
    <List {...props} filters={Filters(props)}>
      <div style={{marginTop: '15px'}}>
        <Datagrid rowClick="show">
          <TextField source="id" />
          <ReferenceField label="Transport" source="transportId" reference="transport">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Origin" source="originId" reference="origins">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="description" />
          <TextField source="waterConsumption" />
          <TextField source="water" />
          <TextField source="climateChange" label="Global warming" />
          <TextField source="eutrophication" />
          <TextField source="fossilFuels" label="Fossil Fuels" />
          <DateField source="createdAt" label="Creation date" />
          <DateField source="updatedAt" label="Update date" />
          <EditButton />
        </Datagrid>
      </div>
    </List>
  );
};

const ShowTransportOrigin = (props) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source="id" />
        <ReferenceField label="Transport" source="transportId" reference="transport">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Origin" source="originId" reference="origins">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="description" />
        <TextField source="primarySource" label="Primary Source" />
        <TextField source="dataGenerator" label="Data Generator" />
        <TextField source="impactGenerator" label="Impact Generator" />
        <TextField source="originalDataset" label="Original Dataset" />
        <TextField source="waterConsumption" label="Water Consumption" />
        <TextField source="water" label="Water" />
        <TextField source="climateChange" label="Global Warming" />
        <TextField source="eutrophication" label="Eutrophication" />
        <TextField source="fossilFuels" label="Fossil Fuels" />
        <DateField source="createdAt" label="Creation date" />
        <DateField source="updatedAt" label="Update date" />
      </SimpleForm>
    </Show>
  );
};

const CreateTransportOrigin = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput label="Transport" source="transportId" reference="transport">
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Origin" source="originId" reference="origins">
          <SelectInput source="name" />
        </ReferenceInput>
        <TextInput source="description" multiline fullWidth />
        <TextInput source="primarySource" label="Primary Source" />
        <TextInput source="dataGenerator" label="Data Generator" />
        <TextInput source="impactGenerator" label="Impact Generator" />
        <TextInput source="originalDataset" label="Original Dataset" />
        <TextInput source="waterConsumption" label="Water Consumption" />
        <TextInput source="water" label="Water" />
        <TextInput source="climateChange" label="Global Warming" />
        <TextInput source="eutrophication" label="Eutrophication" />
        <TextInput source="fossilFuels" label="Fossil Fuels" />
      </SimpleForm>
    </Create>
  );
};

const EditTransportOrigin = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <ReferenceInput label="Transport" source="transportId" reference="transport">
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput label="Origin" source="originId" reference="origins">
          <SelectInput source="name" />
        </ReferenceInput>
        <TextInput source="description" multiline fullWidth />
        <TextInput source="primarySource" label="Primary Source" />
        <TextInput source="dataGenerator" label="Data Generator" />
        <TextInput source="impactGenerator" label="Impact Generator" />
        <TextInput source="originalDataset" label="Original Dataset" />
        <TextInput source="waterConsumption" label="Water Consumption" />
        <TextInput source="water" label="Water" />
        <TextInput source="climateChange" label="Global Warming" />
        <TextInput source="eutrophication" label="Eutrophication" />
        <TextInput source="fossilFuels" label="Fossil Fuels" />
      </SimpleForm>
    </Edit>
  );
};


const transportOriginResource = () => <Resource
  name="transportOrigin"
  options={{ label: 'Transport by origin', menuGroup: 'Bcome'}}
  list={TransportOriginList}
  create={CreateTransportOrigin}
  edit={EditTransportOrigin}
  show={ShowTransportOrigin} />;

export default transportOriginResource;
